import React, { Component } from "react";
import openSocket from "socket.io-client";
import CryptoJS from "react-native-crypto-js";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import GenerateCred from "./components/GenerateCred/generateCred";
import VerifyCreditnals from "./components/VerifyCreditnals/generateCred";
import data from "./api";
import { store } from "react-notifications-component";
import "./App.css";
import Swal from "sweetalert2";


import {faTwitter, faFacebook, faYoutube, faLinkedinIn, faInstagram} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const isTestnet = false;

const socketURL =
  isTestnet === true
    ? "https://socket-stage.myearth.id"
    : "https://socket.myearth.id";
const socket = openSocket(socketURL, {
  transports: ["websocket", "polling", "flashsocket"],
});
let socketId;
socket.on("connect", () => {
  socketId = socket.id;
  console.log(`I'm connected with the back-end`);
});

const {
  listenForUserData,
  listenForServiceProviderResponse,
  getApiKey,
  issuerDidgenerateqr,
  createIssuerDid,
  getUserCreditnals,
  generateqr,verifier_listenForUserData,
  verifier_listenForServiceProviderResponse,
  verifier_generateqr,
  verifyCreditnals
} = data;

let showNotification = true;

class App extends Component {
  state = {
    showSpinner: false,
    showBtn: true,
    requestNo: "",
    qrcode: false,
    response: false,
    email: "",
    mno: "",
    profileDrop: false,
    btnText: "Login with EarthId",
    showText: true,
    showModal: true,
    modalHeading: false,
    createDid: "",
    creditnals: "",
  };

  loader = () => {
    this.setState({ showBtn: false, modalHeading: true });
    this.setState({ qrcode: true, showSpinner: false });
    if (!this.state.showModal) {
      this.setState({
        showModal: true,
      });
    }
    if (this.state.loggedIn) {
      localStorage.removeItem("email");
      localStorage.removeItem("mobile_no");
      localStorage.removeItem("user_name");
      localStorage.removeItem("dob");
      localStorage.removeItem("e_verified");
      localStorage.removeItem("trust_score");
      localStorage.removeItem("earth_id");
      localStorage.removeItem("m_verified");
      this.setState({
        loggedIn: false,
        btnText: "Login with Earth Id",
        qrcode: true,
        response: false,
        showModal: false,
      });
      setTimeout(() => {
        document.getElementById("exampleModal").click();
      }, 0);
      this.redirectToHome();
    }
  };

  componentDidMount = async () => {
    if (localStorage.email != null) {
      this.setState({
        loggedIn: true,
        btnText: "LogOut",
      });
    }
    if (!this.state.apiKey) {
      this.state.apiKey = getApiKey();
    }
  };

  updateNotificationState = () => {
    setTimeout(() => {
      showNotification = true;
    }, 100);
    this.redirectToGenerateCred();
  };

  verifyNotificationState = () => {
    setTimeout(() => {
      showNotification = true;
    }, 100);
    this.redirectToVerifyCreditnals();
  };

  userDenied = () => {
    this.setState({
      response: false,
      showModal: false,
    });
    setTimeout(() => {
      document.getElementById("exampleModal").click();
      if (showNotification === true) {
        showNotification = false;
        store.addNotification({
          title: "Error!",
          message: `User Didn't Authorize!`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      }
    }, 0);
  };

  listenForSocket = () => {
    listenForServiceProviderResponse((err, res) => {
      this.setState({
        qrcode: false,
        response: false,
        showSpinner: true,
        showText: false,
      });
    });

    listenForUserData(async (err, res) => {
      let bytes = (res.userDetails) || (res.newreq)
      console.log("bytes", bytes);
      this.setState({
        showQr: false,
        showSpinner: false,
      });
      let creditnals;
      let sessionKey;
      if (bytes === "denied" || bytes.permission === 'denied') {
        this.userDenied();
      } else if (
        bytes.permission === "granted" ||
        bytes === "issuerLogin" ||
        bytes === "credentialsgenerated" ||
        bytes === "verifylogin" || 
        bytes === "success"
      ) {
        this.setState({
          response: true,
          showSpinner: false,
          loggedIn: true,
          showModal: false,
          btnText: "LogOut",
        });
        setTimeout(() => {
          document.getElementById("exampleModal").click();
          let popMessage;
          if (showNotification === true) {
            if (bytes === "credentialsgenerated") {
              popMessage = "Credentials Generated";
            } else {
              popMessage = `Login Successful`;
            }
            showNotification = false;
            store.addNotification({
              title: "Success!",
              message: popMessage,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            });
            if (bytes.permission === "granted") {
              this.updateNotificationState();
            } else if (bytes === "verifylogin") {
              this.verifyNotificationState();
            }
          }
        }, 0);
      } else if (bytes.userDetails.credentialType != null) {
        console.log("bytes", bytes);
        sessionKey = bytes.sessionKey;
        await getUserCreditnals(bytes.userDetails)
          .then((data) => {
            this.userCreditnalsSuccess(bytes);

            creditnals = data;
          })
          .catch((err) => {
            alert(err);
          });
      } else {
        this.setState({
          response: false,
          showModal: false,
        });
        setTimeout(() => {
          document.getElementById("exampleModal").click();
          if (showNotification === true) {
            showNotification = false;
            store.addNotification({
              title: "Error!",
              message: `User Didn't Authorize!`,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            });
          }
        }, 0);
      }
    });
  };

  userCreditnalsSuccess = (data) => {
    if (data.userDetails.credentialType != null) {
      this.setState({
        response: true,
        showSpinner: false,
        loggedIn: true,
        showModal: false,
        btnText: "LogOut",
      });
      setTimeout(() => {
        document.getElementById("exampleModal").click();
        let popMessage;
        if (showNotification === true) {
          popMessage = "Credentials Generated";
          showNotification = false;
          store.addNotification({
            title: "Success!",
            message: popMessage,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });
        } else {
          this.setState({
            response: false,
            showModal: false,
          });
          setTimeout(() => {
            document.getElementById("exampleModal").click();
            if (showNotification === true) {
              showNotification = false;
              store.addNotification({
                title: "Error!",
                message: `User Didn't Authorize!`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true,
                },
              });
            }
          }, 0);
        }
      }, 0);
    }
  };

  generateQrCodeData = async () => {
    issuerDidgenerateqr("login", "loginDetailsEncryption")
      .then(async (data) => {
        this.listenForSocket();
        this.setState({
          showQr: true,
          qrCodeData: data,
          apiKey: this.state.apikey,
          qrcode: false,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  generateCredQrCodeData = () => {
    issuerDidgenerateqr("generateCredentials")
      .then((data) => {
        this.listenForSocket();
        this.setState({
          showQr: true,
          qrCodeData: data,
          apiKey: this.state.apikey,
          qrcode: false,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  qrcode = () => {
    let { qrCodeData } = this.state;
    return (
      <div className="qr-container">
        <img src={qrCodeData} alt="new" />
      </div>
    );
  };

  redirectToHome() {
    window.location.href = "/";
  }
  redirectToGenerateCred() {
    window.location.href = "/generateCred";
  }

  redirectToVerifyCreditnals() {
    window.location.href = "/verifyCreditnals";
  }


    //VERIFY CREDENTIALS

    verifyQrCodeData = () => {
      verifier_generateqr("shareCredentials")
        .then((data) => {
          // console.log("Error found")
          this.verifier_listenForSocket();
          this.setState({
            showQr: true,
            qrCodeData: data,
            apiKey: this.state.apikey,
            qrcode: false,
          });
        })
        .catch((err) => {
          alert(err.message);
        });
    };
  
    verifier_listenForSocket  = () => {
      verifier_listenForServiceProviderResponse((err, res) => {
        this.setState({
          qrcode: false,
          response: false,
          showSpinner: true,
          showText: false,
        });
      });
  
    verifier_listenForUserData(async (err, res) => {
      let bytes = res.userDetails || res.newreq;
      console.log("bytes", bytes);
      console.log("bytes", `${this.props.location}${bytes}`);
      if (bytes.permission === "denied" || bytes === "denied") {
        this.userDenied();
      } else {
        Swal.fire({
          // title: 'Are you sure?',
          text: bytes.credential,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Verify",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              position: "center",
              //icon: 'success',
              title: "Verifying....",
              showConfirmButton: false,
            });
            verifyCreditnals(
              bytes.credential,
              bytes.publicKey,
              bytes.signature,
              bytes.verifyParams
            ).then((data) => {
              if (data.success === true) {
                Swal.fire("Successfully Verified");
              } else {
                Swal.fire("Verification Failed");
              }
            });
          }
        });
        this.setState({
          showQr: false,
          showSpinner: false,
        });
        if (bytes != null) {
          this.setState({
            response: true,
            showSpinner: false,
            loggedIn: true,
            showModal: false,
            btnText: "LogOut",
          });
          setTimeout(() => {
            document.getElementById("exampleModal").click();
            let popMessage;
            if (showNotification === true) {
              if (bytes === "credentialsgenerated") {
                popMessage = "Credentials Generated";
              } else {
                popMessage = `Credentials Received Successfully`;
              }
              showNotification = false;
              store.addNotification({
                title: "Success!",
                message: popMessage,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true,
                },
              });
              if (bytes == "login") {
                this.updateNotificationState();
              } else if (bytes === "verifylogin") {
                this.verifyNotificationState();
              }
            }
          }, 0);
        } else {
          this.setState({
            response: false,
            showModal: false,
          });
          setTimeout(() => {
            document.getElementById("exampleModal").click();
            if (showNotification === true) {
              showNotification = false;
              store.addNotification({
                title: "Error!",
                message: `User Didn't Authorize!`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true,
                },
              });
            }
          }, 0);
        }
      }
    });
  };

  render() {
    let showButton = this.state.showSpinner ? (
      <div className="spinner-div">
        <img className="spinner" src={require("./spinner.gif")} />
      </div>
    ) : null;
    return (
      <div className="App">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div
            className="collapse navbar-collapse pull-right container"
            id="navbarTogglerDemo01"
          >
            <div className="clickable" onClick={() => this.redirectToHome()}>
              <img src={require("./earthid-logo.png")} alt="logo" className="logo" width="160" height="50" />{" "}
              {/* <span className="logo-text">&nbsp;Issuer</span> */}
            </div>
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                    <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#">Save</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Borrow</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Payments</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Invest</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Rewards</a>
                    </li>
            </ul>

            {localStorage.user_name ? (
              <div>
                <label className="welcomeLabel">{`Welcome, ${localStorage.getItem(
                  "user_name"
                )}`}</label>
              </div>
            ) : null}

            <div className="profileAccess">
              {window.location.pathname == "/" ? (
                <div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={this.generateQrCodeData}
                  >
                    {this.state.btnText}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary mx-2"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={this.generateCredQrCodeData}
                  >
                    Generate Credentials
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary mx-2"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={this.verifyQrCodeData
                    }
                  >
                    Verify Credentials
                  </button>
                </div>
              ) : window.location.pathname == "/verifyCreditnals" ? (
                <div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={this.verifyQrCodeData}
                  >
                    Verify Creditanals
                  </button>{" "}
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={this.redirectToHome}
                  >
                    Logout
                  </button>
                </div>
              ) : (
                <div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={this.generateCredQrCodeData}
                  >
                    {" "}
                    Generate Credentials
                  </button>{" "}
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={this.redirectToHome}
                  >
                    Logout
                  </button>
                </div>
              )}

              {this.state.loggedIn ? (
                <div className="dropDown">
                  {this.state.profileDrop ? (
                    <div>
                      <ul className="dropdown-profile">
                        <li
                          className="dropdown-list1"
                          onClick={() => this.redirectToProfile()}
                        >
                          My Profile
                        </li>
                        <li
                          className="dropdown-list1"
                          onClick={() => this.redirectToDocuments()}
                        >
                          {" "}
                          Submit Kyc Documents
                        </li>
                        <li
                          className="dropdown-list1"
                          onClick={() => this.redirectToGenerateCred()}
                        >
                          {" "}
                          Generate Credentials
                        </li>
                        <li className="dropdown-list" onClick={this.loader}>
                          LogOut
                        </li>
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </nav>
        <header>
          <section class="home1">
            <div class="container">
              <div class="row">

                <div class="col-md-6">
                  <h3>Banking made awesome</h3>
                  <hr />
                  <p>Spend, save, invest, and control your entire financial life — in one place.</p>
                  <a class="left1" href="#">Join Now!</a>
                </div>
              </div>
            </div>
          </section>
          <section class="home2">
            <div class="container">
              <h2>We're not about money. We're about trust. </h2>
              <div class="row">
                <div class="extra-div col-md-4">
                  <h2>01</h2>
                  <h2>Savings</h2>
                  <hr/>
                  <h3>Your NEO Smart account comes with a companion Stash account to put savings in.Move money between them in a flash.</h3>
                </div>
                <div class="extra-div col-md-4">
                  <h2>02</h2>
                  <h2>Solutions</h2>
                  <hr/>
                  <h3>NEO is all digital.No more waiting in line at your local branch.Do anything you need on the NEO app.</h3>
                </div>
                <div class="extra-div col-md-4">
                  <h2>03</h2>
                  <h2>Smarts</h2>
                  <hr/>
                  <h3>Intelligent algorithms help automate your finances to get the most out of your money.Enjoy the peace of mind.</h3>
                </div>
              </div>
            </div>
          </section>

          <section class="home3">
            <div class="container">
              <div class="col-md-7">
                <h2>Get a bank account that can do more</h2>
                <h4>Tracing elusive spends and counting decimal points isn't on you. NEO's rapid payment systems, intelligent insights, and savings tools help you automate the boring stuff — so you can chase after the big things.</h4>

              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
              </div>
              <div class="types col-md-4">
                <h2>01</h2>
                <h3>Change how you bank</h3>
                <p>Cut through the clutter and make informed financial decisions. Whatever you need money to do, NEO's got it covered.</p>
              </div>

            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="types1 col-md-4">
                <h2>02</h2>
                <h3>Insights done right</h3>
                <p>See every spend better and track expenditures in one place. Your money isn’t a mystery anymore.</p>
              </div>
              <div class="col-md-5">
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
              </div>
              <div class="types2 col-md-4">
                <h2>03</h2>
                <h3>Everything you need, in one app</h3>
                <p>Payments? Check. Spend analytics? Check. Savings goals? Check. Life just got a lot better.</p>
              </div>

            </div>

          </section>

          <footer class="footerr">
            <div class="container">

              <div class="row">
                <div class="col-md-4">
                  <h2>NEO
                    ‍Reimagine your financial life</h2>

                </div>
                <div class="social col-md-8">
                  <h2>Follow Us:</h2>

                  <a href="https://twitter.com/earthid_ssi" target="blank"><FontAwesomeIcon icon={faTwitter} /></a>
                  <a href="https://www.facebook.com/myearth.id/" target="blank"><FontAwesomeIcon icon={faFacebook} /></a>
                  <a href="https://www.linkedin.com/company/myearthid/" target="blank"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                  <a href="https://www.youtube.com/channel/UCaRjLyN4I_0UrK3NL4zU1Vg/featured" target="blank"><FontAwesomeIcon icon={faYoutube} /></a>
                  <a href="https://www.instagram.com/myearthid/" target="blank"><FontAwesomeIcon icon={faInstagram} /></a>
                  <a href="#"><FontAwesomeIcon icon={faEnvelope} /></a>

                </div>
              </div>
            </div>
          </footer>
          {window.location.pathname == "/" ? (
            <div className="hero-section">
              <div className="shoppingCart"></div>
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  {
                    <div>
                      {this.state.showModal ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4>
                              {this.state.modalHeading &&
                              !this.state.showSpinner
                                ? "Use Earth ID app to scan the QR code"
                                : "Waiting for user authorization"}
                            </h4>
                          </div>
                          <div className="modal-body">
                            <div className="pop-upbox">
                              {showButton}
                              {this.state.response
                                ? null
                                : this.state.qrcode
                                ? this.generateQrCodeData()
                                : null}
                              {this.state.showQr ? (
                                this.qrcode()
                              ) : this.state.showSpinner ? (
                                <img
                                  className="spinner"
                                  src={require("./spinner.gif")}
                                />
                              ) : null}
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary p-btn"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  }
                </div>
              </div>
            </div>
          ) : (
            <div className="hero-section">

              <div className="shoppingCart"></div>
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  {
                    <div>
                      {this.state.showModal ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4>
                              {this.state.modalHeading &&
                              !this.state.showSpinner
                                ? "Use Earth ID app to scan the QR code"
                                : "Waiting for user authorization"}
                            </h4>
                          </div>
                          <div className="modal-body">
                            <div className="pop-upbox">
                              {showButton}
                              {this.state.response
                                ? null
                                : this.state.qrcode
                                ? this.generateCredQrCodeData()
                                : null}
                              {this.state.showQr ? (
                                this.qrcode()
                              ) : this.state.showSpinner ? (
                                <img
                                  className="spinner"
                                  src={require("./spinner.gif")}
                                />
                              ) : null}
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary p-btn"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  }
                </div>
              </div>
            </div>
          )}
          <div className="content">
            <Router>
              <Switch>
                <Route path="/generateCred" component={GenerateCred} />
                <Route path="/verifyCreditnals" component={VerifyCreditnals} />
              </Switch>
            </Router>
          </div>
        </header>
      </div>
    );
  }
}

export default App;
